@use "../abstracts" as *;
@use "../framework/button" as *;

.form-group {
  position: relative;
  margin-bottom: 2.4rem;
  .form-label {
    display: block;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.6rem;
    color: var(--abcis--black);
    margin-bottom: 0.8rem;
    + .form-value {
      margin-top: 0rem;
      display: block;
    }
  }

  .form-value {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 2rem;
    color: var(--rmt--black);
  }

  &--column {
    @extend %flex-col;
  }
  &--search {
    .icon-svg[title="search"] {
      position: absolute;
      top: 1.4rem;
      left: 1.2rem;
      color: #989595;
    }
    .app-c-btn--icon-only {
      position: absolute;
      top: 1.6rem;
      right: 1.2rem;
      color: #989595;
      display: none;
    }
    .form-control {
      padding-left: 3.5rem;
      height: 4rem;
      border-radius: 0.8rem;
      transition: all ease 0.3s;
    }
    &.active {
      .form-control {
        padding-left: 1.2rem;
        + .icon-svg[title="search"] {
          display: none;
        }
        ~ .app-c-btn--icon-only {
          display: block;
        }
      }
    }
  }
  &.required {
    .form-label {
      &::after {
        content: "*";
        color: var(--rmt--red);
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 1.4rem;
      }
    }
  }
}
.form-control {
  display: block;
  width: 100%;
  padding: 1.2rem 1.4rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: normal;
  background: var(--white);
  border: 0.18rem solid var(--abcis--black-750);
  border-radius: 0.6rem;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 4.8rem;
  color: var(--abcis--black);
  &:hover {
    border-color: var(--abcis--black-100);
  }
  &:active,
  &:focus {
    border-color: var(--abcis--black-100);
    outline: none;
    box-shadow: none;
  }
  &:disabled,
  &.disabled {
    background: var(--abcis--black-900);
    pointer-events: none;
    user-select: none;
    cursor: default;
  }
}

.form-control--md {
  display: block;
  width: 100%;
  padding: 1.2rem 1.4rem;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: normal;
  background: var(--white);
  border: 0.18rem solid var(--abcis--black-750);
  border-radius: 0.6rem;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 4.8rem;
  color: var(--abcis--black);
  &:hover {
    border-color: var(--abcis--black-100);
  }
  &:active,
  &:focus {
    border-color: var(--abcis--black-100);
    outline: none;
    box-shadow: none;
  }
  &:disabled,
  &.disabled {
    background: var(--abcis--black-900);
    pointer-events: none;
    user-select: none;
    cursor: default;
  }
}


.form-control--sm {
  display: block;
  width: 100%;
  padding: 0.9rem 1.2rem;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: normal;
  background: var(--white);
  border: 0.18rem solid var(--abcis--black-750);
  border-radius: 0.6rem;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 4rem;
  &:hover {
    border-color: var(--abcis--black-100);
  }
  &:active,
  &:focus {
    border-color: var(--abcis--black-100);
    outline: none;
    box-shadow: none;
  }
  &:disabled,
  &.disabled {
    background: var(--abcis--black-900);
    pointer-events: none;
    user-select: none;
    cursor: default;
  }
}

.form-text {
  color: var(--abcis--red);
  font-size: 1.3rem;
  line-height: 1.6rem;
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: 0;
  margin-bottom: 8px;
  z-index: 1;
  font-weight: 500;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;
}
.form-error {
  .form-text {
    color: var(--abcis--red);
    opacity: 1;
    visibility: visible;
  }
}
.form-success {
  .form-control {
    border-color: var(--abcis--green);
  }
  .help-block {
    color: var(--abcis--green);
  }
}
