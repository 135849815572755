:root {
  --abcis--purple-100: #36235e;
  --abcis--purple-200: #482f7e;
  --abcis--purple-300: #5a3b9d;
  --abcis--purple-400: #6b4fa7;
  --abcis--purple-500: #7b62b1;
  --abcis--purple-600: #8c76ba;
  --abcis--purple-700: #9c89c4;
  --abcis--purple-750: #ad9dce;
  --abcis--purple-800: #bdb1d8;
  --abcis--purple-850: #cec4e2;
  --abcis--purple-900: #ded8eb;
  --abcis--purple-950: #efebf5;

  --abcis--black: #0e150e;
  --abcis--black-100: #1a1a1a;
  --abcis--black-200: #2b2931;
  --abcis--black-300: #403e49;
  --abcis--black-400: #545160;
  --abcis--black-500: #666274;
  --abcis--black-600: #827e90;
  --abcis--black-700: #9e9aad;
  --abcis--black-750: #b4b4b4;
  --abcis--black-800: #dbd9de;
  --abcis--black-850: #eae9eb;
  --abcis--black-900: #f5f5f5;
  --abcis--black-950: #f9f9f9;

  --abcis--green-100: #00693e;
  --abcis--green-200: #007847;
  --abcis--green-300: #009659;
  --abcis--green: #009659;
  --abcis--green-condition: #55b35c;
  --abcis--green-400: #4db68b;
  --abcis--green-500: #99d5bd;
  --abcis--green-600: #e6f5ee;

  --abcis--yellow-100: #ae7204;
  --abcis--yellow-200: #c68205;
  --abcis--yellow-300: #f8a306;
  --abcis--yellow: #f8a306;
  --abcis--yellow-400: #fabf51;
  --abcis--yellow-500: #fcda9b;
  --abcis--yellow-600: #fef6e6;
  --abcis--yellow-700: #ffeeb1;

  --abcis--red-100: #a53d3d;
  --abcis--red-200: #bc4646;
  --abcis--red-300: #eb5757;
  --abcis--red: #ff0000;
  --abcis--red-400: #f18989;
  --abcis--red-500: #f7bcbc;
  --abcis--red-600: #fdeeee;

  /* common variables */
  --abcis--brand-primary: #ee383a;
  --abcis--brand-primary-hover: #ff1c1e;
  --abcis--brand-accent: #385072;
  --abcis--footer-bg: #cddbf3;
  --white: #fff;
  --white--rgb: 255, 255, 255;
  --white--rgb: 255, 255, 255;
  --black: #000;
  --black--rgb: 0, 0, 0;
  --abcis-pale-grey: #f1f6fa;
  --abcis-dark-grey: #828282;
  --login-bg: #f8f1ee;
  --body-bg: #f6f7fa;
  --abcis--link: #0061ff;
  --abcis--blue: #52aae3;
}
