@use "../abstracts" as *;
@use "../framework/button" as *;

.app-c-btn {
  @include button;
  @extend %inline-flex-all-center;
  padding: 1.6rem 3.2rem !important;
  font-family: $body-font !important;
  font-size: 1.8rem !important;
  font-weight: $font-weight-semi-bold !important;
  text-decoration: none !important;
  border: 0.1rem solid transparent !important;
  border-radius: $btn-border-radius !important;
  background-color: transparent !important;
  background-clip: padding-box !important;
  transition: background-color ease $animation-duration-3,
    border-color ease $animation-duration-3;
  position: relative;
  @extend %touch-tap-none;
  height: 5.9rem !important;
  min-width: 18rem !important;
  text-transform: initial !important;
  &:disabled,
  &.disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }
  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 0.8rem;
    transform: translateX(0rem);
    transition: transform ease $animation-duration-3;
  }
  // button -- primary
  &--primary {
    background-color: var(--abcis--brand-primary) !important;
    border: 0.1rem solid var(--abcis--brand-primary) !important;
    color: var(--white) !important;
    box-shadow: none !important;

    &:hover {
      border-color: var(--abcis--brand-primary) !important;
      background-color: var(--abcis--brand-primary-hover) !important;
      svg {
        transform: translateX(0.5rem);
      }
    }
    &:focus,
    &:active {
      border-color: var(--abcis--brand-primary) !important;
      background-color: var(--abcis--brand-primary) !important;
    }
    &:disabled,
    &.disabled {
      border-color: var(--abcis--black-750) !important;
      background-color: var(--abcis--black-750) !important;
      cursor: not-allowed;
      pointer-events: none;
      user-select: none;
    }
  }
  + .app-c-btn {
    margin-left: 1.2rem;
  }
}
