@use "abstracts" as *;

:root {
  --asc-display: none;
  --desc-display: none;
  --no-sort-display: block;
}

html,
body {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  font-family: $body-font;
  font-size: $text-md;
  /* 1.4rem */
  font-weight: $font-weight-regular;
  overflow: hidden;
}
#root {
  @extend %flex-col;
  height: 100%;
  min-height: 0;
}

button {
  font-family: $body-font;
}

.app-c-link {
  color: #0061ff;
  text-decoration: underline;
  cursor: pointer;
}

// Header
.app-c-header {
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 4rem;
  position: relative;
  background: var(--abcis--brand-accent);
  min-height: 6.3rem;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: auto;
    left: 0;
    right: 0;
    height: 0.3rem;
    background: var(--abcis--brand-primary);
  }
}

// Footer
.app-c-footer {
  width: 100%;
  padding: 24px;
  @extend %flex-vert-center;
  .app-c-footer__left {
    margin-right: auto;
  }
  .app-c-footer__right {
    margin-left: auto;
    @extend %inline-flex-vert-center;
  }
  .app-c-footer__powered-by-text {
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: -0.11px;
    color: var(--black);
    margin-right: 0.8rem;
  }
}
.app-l-page-wrap {
  @extend %flex-col;
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: hidden;
}

.app-l-content-wrap {
  @extend %flex-col;
  width: 100%;
  height: 100%;
  min-height: 0;
  min-width: 0;
  overflow: auto;
  transition: all ease-in-out 0.3s;

  @media (max-width: $screen-ipad-landscape) {
    // padding-left: 7.2rem;
  }
}
.app-l-banner__wrap {
  padding: 0 11.2rem;
  height: 100%;
  @extend %flex-col;
  @media (max-width: $screen-ipad-landscape) {
    padding: 4rem 4rem;
  }
}
.app-l-banner {
  @extend %flex-vert-center;
  margin-top: auto;
  margin-bottom: auto;
  @media (max-width: $screen-ipad-landscape) {
    flex-direction: column;
  }
  .app-l-banner__left {
    max-width: 52rem;
    @extend %flex-horiz-start-col;
    @media (max-width: $screen-ipad-landscape) {
      flex-direction: column;
      align-items: center;
    }
  }
  .app-l-banner__heading {
    font-size: 6.8rem;
    font-weight: 700;
    line-height: 7.6rem;
    letter-spacing: -0.68px;
    color: var(--abcis--black);
    @media (max-width: $screen-ipad-landscape) {
      font-size: 4.8rem;
      line-height: 5.6rem;
      text-align: center;
    }
    @media (max-width: $screen-sm) {
      font-size: 3rem;
      line-height: 3.6rem;
    }
  }
  .app-l-banner__desc {
    margin-top: 3.2rem;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.7rem;
    @media (max-width: $screen-ipad-landscape) {
      font-size: 1.6rem;
      line-height: 2.2rem;
      text-align: center;
    }
    @media (max-width: $screen-sm) {
      font-size: 1.4rem;
      line-height: 1.8rem;
      margin-top: 1.2rem;
    }
  }
  .app-c-btn {
    margin-top: 32px;
    @media (max-width: $screen-ipad-landscape) {
      height: 4rem !important;
      font-size: 1.4rem !important;
      min-width: auto !important;
    }
  }
  .app-l-banner__right {
    margin-left: auto;
    img {
      max-width: 56.3rem;
      @media (max-width: $screen-ipad-landscape) {
        max-width: 100%;
      }
    }
    @media (max-width: $screen-ipad-landscape) {
      margin-top: 3rem;
      margin-left: 0;
    }
  }
}
.gradient-bg {
  .app-c-footer {
    position: absolute;
    bottom: 0;
    z-index: 1;
    &::after {
      content: "";
      position: absolute;
      border-radius: 100px;
      background: var(--abcis--footer-bg);
      filter: blur(100px);
      bottom: 0;
      top: auto;
      left: 0;
      right: 0;
      height: 85px;
      z-index: -1;
    }
  }
}

.app-l-login__wrap {
  height: 100%;
  @extend %flex-col;
}
.app-l-login {
  @extend %flex-row;
  height: 100%;
  .app-l-login__left {
    min-width: 41rem;
    max-width: 41rem;
    background: var(--abcis--brand-accent);
    @extend %flex-all-center-col;
    padding: 1.2rem 4rem;
    position: relative;
    @media (max-width: $screen-ipad-landscape) {
      flex-direction: column;
      align-items: center;
      min-width: 30rem;
      max-width: 30rem;
    }
    @media (max-width: $screen-sm) {
      display: none;
    }
    .app-c-login-header__logo {
      position: absolute;
      top: 1.2rem;
      left: 4rem;
      margin-bottom: 0;
      a {
        outline: none;
      }
    }
    .app-l-login__left__graphics {
      position: relative;
      padding-bottom: 5.4rem;
      text-align: center;
      &::after {
        content: "";
        position: absolute;
        width: 5rem;
        height: 0.6rem;
        left: 0;
        right: 0;
        margin: auto;
        background: var(--abcis--brand-primary);
        bottom: 0;
        top: auto;
      }
      img {
        margin: auto;
      }
    }
    .app-l-login__left__list {
      margin-top: 4.8rem;
      max-width: 24rem;
      @extend %flex-horiz-center-col;
      ul {
        margin: 0;
        padding: 0;
        li {
          @extend %flex-vert-start;
          svg {
            margin-right: 1.2rem;
            width: 2.5rem;
            height: 1.6rem;
            color: var(--abcis--brand-primary);
            margin-top: 3px;
          }
          span {
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: var(--white);
          }
          + li {
            margin-top: 2.4rem;
          }
        }
      }
    }
  }
  .app-l-login__heading {
    font-size: 6.8rem;
    font-weight: 700;
    line-height: 7.6rem;
    letter-spacing: -0.68px;
    color: var(--abcis--black);
    @media (max-width: $screen-ipad-landscape) {
      font-size: 4.8rem;
      line-height: 5.6rem;
      text-align: center;
    }
    @media (max-width: $screen-sm) {
      font-size: 3rem;
      line-height: 3.6rem;
    }
  }
  .app-l-login__desc {
    margin-top: 3.2rem;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.7rem;
    @media (max-width: $screen-ipad-landscape) {
      font-size: 1.6rem;
      line-height: 2.2rem;
      text-align: center;
    }
    @media (max-width: $screen-sm) {
      font-size: 1.4rem;
      line-height: 1.8rem;
      margin-top: 1.2rem;
    }
  }
  .app-c-btn {
    margin-top: 0.8rem;
    @media (max-width: $screen-ipad-landscape) {
      height: 4rem !important;
      font-size: 1.4rem !important;
      min-width: auto !important;
    }
  }
  .app-l-login__right {
    @extend %flex-all-center-col;
    width: 100%;
    height: 100%;
    position: relative;
    background: var(--abcis-pale-grey);
    .app-l-login__heading {
      @extend %flex-vert-center;
      margin-bottom: 3.2rem;
      span {
        font-size: 2.4rem;
        line-height: 2.9rem;
        color: var(--abcis--black);
        margin-right: 0.5rem;
      }
    }
    @media (max-width: $screen-ipad-landscape) {
      margin-left: 0;
    }
    .app-l-login__form {
      width: 40rem;
      @media (max-width: $screen-sm) {
        width: calc(100% - 3rem);
      }
    }
    .app-c-footer {
      position: absolute;
      bottom: 0;
      z-index: 1;
    }
  }
}
.app-l-content-inner-wrap {
  padding: 1.6rem;
  min-height: 0;
  background: var(--body-bg);
}
.icon-button {
  @extend %flex;

  .svg-icon + .button-text {
    display: inline-block;
    margin-left: 1.5rem;
  }
}

.icon-button .button-text {
  margin-left: 0.8rem;
  margin-right: 0.3rem;
}

.export-btn .button-text {
  margin-right: 1rem;
}

.sr-only,
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 0.1rem;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 0.1rem;
}

::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.7rem;
}

::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 .4rem rgba(0, 0, 0, 0.3);
  // -webkit-border-radius: 1.0rem;
  // border-radius: 1.0rem;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0.3rem;
  border-radius: 0.3rem;
  background: rgba(191, 195, 206, 0.7);
  // -webkit-box-shadow: inset 0 0 .4rem rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(191, 195, 206, 0.7);
  -webkit-border-radius: 0.3rem;
  border-radius: 0.3rem;
}
