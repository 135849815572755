@use "./variables" as *;
@use "./breakpoints" as *;
@use "./typography" as *;
@use "./palette" as *;

.is-block {
  display: block;
}

.is-d-none {
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
  z-index: -1;
}

.img-auto {
  max-width: 100%;
  height: auto;
}
